import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const MultiPocketFintessSorter = ({ data }) => {
  return (
    <Layout>
      <SEO title="PA9500 Multi-Pocket Fitness Sorter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              PA9500 Multi-Pocket Fitness Sorter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Restlessly in search of greater performance
            </h3>
            <p className="mb-4">
              PA9500, another revolutionary product from PA, is so far the most
              powerful fitness sorter in PA product portfolio, featuring
              expandable modular stacker design plus enhanced and reliable
              banknote authentication and fitness-sorting.
            </p>
            <p className="mb-4">
              PA9500 stops suspicious notes by using the most advanced
              counterfeit detection technology in the industry. With the
              expandable stacker design, user is able to process banknotes in
              the shortest time.
            </p>
            <p className="mb-4">
              Easy modes switching to fulfill all kind of cash handling process,
              it reduces workload with high speed and accuracy. Supporting most
              of the currencies worldwide, and various possibilities for
              connectivity, PA9500 satisfies the demand for large banknote
              handling environment.
            </p>

            <h3 className="mb-6 text-3xl font-bold leading-none">
              Various Sorting Modes and Features
            </h3>
            <ul className="flex flex-wrap mb-4 ml-4 list-disc">
              <li className="w-1/2">Count</li>
              <li className="w-1/2">Mix</li>
              <li className="w-1/2">Sort</li>
              <li className="w-1/2">Face</li>
              <li className="w-1/2">Orientation</li>
              <li className="w-1/2">Emission</li>
              <li className="w-1/2">Fitness</li>
              <li className="w-1/2">S/N Scan</li>
              <li className="w-1/2">Cheque Scan</li>
              <li className="w-1/2">TITO</li>
            </ul>

            <h3 className="mb-6 text-3xl font-bold leading-none">
              Exceptional User Experience
            </h3>
            <p className="mb-4">
              User experience defines the last mile of a good product. Complex
              user experience can be exhausting and dragging down the
              efficiency. PA invested extra efforts to bring users the best of
              its technology, to significantly increase cash processing quality
              and efficiency.
            </p>
            <p className="mb-4">
              By integrating a 7" LCD touch panel, PA9500 is able to provide an
              even more intuitive and easy way of operation, showing all useful
              information during banknote processing and lower the learning
              curve at the same time.
            </p>
            <p className="mb-4">
              PA9500 is well-designed in order to perform fast and convenient
              jam removal with a pull of lever. This ensures the shortest time
              possible to restore back to operational state.
            </p>
            <p className="mb-4">
              After-sales service and connectivity lead to up-to-date, easy and
              various methods of data output and software upgrade, to overcome
              case of new adaptation and emissions.
            </p>

            <h3 className="mb-6 text-3xl font-bold leading-none">Sensors</h3>
            <p className="mb-4">
              PA9500 features several types of sensors, which deliver the
              ultimate performance, high accuracy and heavy-duty operation.
            </p>
            <p className="mb-4">
              Each of the sensors is designed to detect different security
              features, ensuring all known suspicious banknotes are caught.
            </p>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Types of sensors</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Contact Image Sensor</td>
                        <td>Red, Green, IR light source. 1584 dots / scan.</td>
                      </tr>
                      <tr>
                        <td>Thickness Sensor</td>
                        <td>19 Channels</td>
                      </tr>

                      <tr>
                        <td>MG Sensor</td>
                        <td>9 Channels</td>
                      </tr>
                      <tr>
                        <td>UV Sensor</td>
                        <td>8 Channels</td>
                      </tr>
                      <tr>
                        <td>Light Source for Transmission Image</td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specs</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Feed System</td>
                        <td>Roller Friction System</td>
                      </tr>
                      <tr>
                        <td>Sorting Modes</td>
                        <td>
                          Count, Mix, Sort, Face, Orientation, Fitness, Emission
                        </td>
                      </tr>
                      <tr>
                        <td>Optional Features</td>
                        <td>S/N Scan, Cheque Scan, TITO</td>
                      </tr>
                      <td>Counterfeit Detection</td>
                      <td>
                        Visible Light <br />
                        Infrared <br />
                        Magnetic <br />
                        Ultraviolet
                      </td>
                      <tr>
                        <td>Capacity</td>
                        <td>
                          1000 Banknotes (Hopper) <br />
                          200 Banknotes (Per Stacker) <br />
                          100 Banknotes (Reject Pocket)
                        </td>
                      </tr>
                      <tr>
                        <td>Sorting Speed</td>
                        <td>
                          1000 Note/Min. (High) <br />
                          900 Note/Min. (Mid) <br />
                          750 Note/Min. (Low)
                        </td>
                      </tr>
                      <tr>
                        <td>Available Currencies</td>
                        <td>
                          USD, EUR, GBP, LOCAL (OPTION), Up to 10 currencies
                        </td>
                      </tr>
                      <tr>
                        <td>Countable Note Size</td>
                        <td>50 x 100 x 0.06mm ~ 90 x 185 x 0.12mm</td>
                      </tr>
                      <tr>
                        <td>Display</td>
                        <td>7" Touch Screen</td>
                      </tr>
                      <tr>
                        <td>Interface</td>
                        <td>LAN, RS232, USB, External Display</td>
                      </tr>

                      <tr>
                        <td>Power Supply</td>
                        <td>AC100V ~ 240V, 50/60Hz</td>
                      </tr>
                      <tr>
                        <td>Consumption</td>
                        <td>Max. 200W</td>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>
                          Approx. 700 x 350 x 370 mm (Main Unit) <br />
                          Approx. 180 x 350 x 270 mm (Extra Stacker)
                        </td>
                      </tr>
                      <tr>
                        <td>Net Weight</td>
                        <td>
                          Approx. 50Kg (Main Unit) <br />
                          Approx. 7Kg (Extra Stacker)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/2">
          <div className="w-full max-w-md px-4">
            <Image
              fluid={data.MultiPocketFintessSorter.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

MultiPocketFintessSorter.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query MultiPocketFintessSorterQuery {
    MultiPocketFintessSorter: file(
      relativePath: { eq: "products/nc9500/main.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default MultiPocketFintessSorter;
